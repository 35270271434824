import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67')
];

export const server_loads = [0,21,22,9,10,16,11,14,18,20,19];

export const dictionary = {
		"/(main)": [~23],
		"/blog/(admin)/admin-suggestions": [~41,[6]],
		"/blog/(admin)/admin-suggestions/[id]": [~42,[6]],
		"/blog/(admin)/admin-suggestions/[id]/edit": [~43,[7],[8]],
		"/blog/all": [57,[21]],
		"/blog/all/[id]": [~58,[21]],
		"/blog/best": [59,[22]],
		"/blog/best/[id]": [~60,[22]],
		"/blog/(membership)/membership": [~44,[9]],
		"/blog/(membership)/membership/write": [~46,[10]],
		"/blog/(membership)/membership/[id]": [~45,[9]],
		"/blog/(membership)/request-data-admin": [~51,[16]],
		"/blog/(membership)/request-data-admin/[id]/edit": [~52,[17]],
		"/blog/(membership)/request-data": [~47,[11]],
		"/blog/(membership)/request-data/write": [~50,[14],[15]],
		"/blog/(membership)/request-data/[id]": [~48,[11]],
		"/blog/(membership)/request-data/[id]/edit": [~49,[12],[13]],
		"/blog/[url]": [~53,[18]],
		"/blog/[url]/write": [~56,[20]],
		"/blog/[url]/[id]": [~54,[18]],
		"/blog/[url]/[id]/edit": [~55,[19]],
		"/login": [~61],
		"/(my-page)/my-page": [~24,[],[2]],
		"/(my-page)/my-page/blocked": [~25,[],[2]],
		"/(my-page)/my-page/blogs-liked": [~27,[],[2]],
		"/(my-page)/my-page/blogs-scraped": [~28,[],[2]],
		"/(my-page)/my-page/blogs": [~26,[],[2]],
		"/(my-page)/my-page/comments-liked": [~30,[],[2]],
		"/(my-page)/my-page/comments": [~29,[],[2]],
		"/(my-page)/my-page/suggestions": [~31,[,3],[2]],
		"/(my-page)/my-page/suggestions/write": [~34,[,3],[2]],
		"/(my-page)/my-page/suggestions/[id]": [~32,[,3],[2]],
		"/(my-page)/my-page/suggestions/[id]/edit": [~33,[4],[5]],
		"/(my-page)/notifications": [~35],
		"/(my-page)/points": [36],
		"/(policy)/privacy-policy": [~38],
		"/(my-page)/ps-oauth-callback": [~37],
		"/require-membership/expert": [62],
		"/require-membership/premium": [63],
		"/signup": [~64],
		"/(policy)/teenager-policy": [~39],
		"/test_component": [65],
		"/tierlist": [~66],
		"/tierlist/create": [~67],
		"/(policy)/use-policy": [~40]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';