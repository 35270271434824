import { handleErrorWithSentry, replayIntegration } from "@sentry/sveltekit";
import * as Sentry from '@sentry/sveltekit';
import {
  isSentryReportingEnabled,
  SENTRY_REPORTED_ENVIRONMENT,
} from '$lib/utils/is-sentry-reporting-enabled.util';

async function initSentry() {
  Sentry.init({
    dsn: 'https://97ffcd95b4a8d663e7fd52e7e377adc6@o4506966510927872.ingest.us.sentry.io/4506966514466816',
    tracesSampleRate: 1.0,
  
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
  
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    
    // If you don't want to use Session Replay, just remove the line below:
    integrations: [replayIntegration()],

    environment: SENTRY_REPORTED_ENVIRONMENT,
  });
}


// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = isSentryReportingEnabled()
  ? handleErrorWithSentry()
  : undefined;
