import { dev } from '$app/environment';

const NODE_ENV = process.env.NODE_ENV;
/**
 * dockerfile에서 사용하는 NODE_ENV
 * local환경에서도 VITE가 기본적으로 development로 설정해주므로 이와 구분하기 위함
 */
const _envsDeployed = new Set<string | undefined>(['prod', 'dev', 'stg']);
export const SENTRY_REPORTED_ENVIRONMENT = _envsDeployed.has(NODE_ENV)
  ? NODE_ENV
  : 'local';

export const isSentryReportingEnabled = () => {
  return !(dev || SENTRY_REPORTED_ENVIRONMENT === 'local');
};
